import React, { useState } from 'react';
import { RequestQuoteFormComponent } from '@tradesorg/component-library-waas-templates.base-request-quote-form';
import styles from './index.module.scss';
import MobileBackground from '@assets/images/mobile-form-bg.png';
import { Formik } from 'formik';
import { requestQuote } from '../../../../utils/requestQuote';
import SuccessModal from '../../../../components/commons/successModal';
import HeroImg from '@assets/images/template-2/hero.jpg';

const Intro = ({ data },props) => {
    const { title, subTitle, image } = data
    const [showModal, setShowModal] = useState(false);
    const [initialValues, setInitialValues] = useState({
        'name': '',
        'phone': '',
        'email' : ''
    });
    const [isLoading,setLoading] = useState(false)
     
    let errorMessage;
   
    return (
        <div className={`introComponentWrapper grid grid-cols-1 md-1:grid-cols-3 xl:grid-cols-2 xl:pl-98px justify-center items-center`}  >
            <div className="mx-auto px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1 relative">
            <h2 className="mt-4 lg:text-xl text-purple xs:text-lg font-bold">{data?.title}</h2>
                <h1 className="text-3xl font-bold md-1:text-5xl w-full md-1:w-11/12 xl:w-2/3">
                {data?.usp?.option}
                </h1>
                <div className="mt-6 text-gray-700 text-sm   w-full md-1:w-10/12 xl:w-2/3">
                   { subTitle }
                </div>
                <div className={`mt-6 hidden md-1:block z-10 ${styles.requestForm}`}>
                    <Formik initialValues={initialValues} onSubmit={async(values)=>{
                         if(!!values.email.length || !!values.phone.length){
                            setLoading(true)
                            const response = await requestQuote({name : values.name , phone_number : values.phone , email : values.email})
                            try {
                                if( response.status === "SUCCESS"){
                                    setShowModal(true)
                                    setLoading(false)
                                }
                            }
                            catch{
                                console.log(response)
                                setLoading(false)
                            }
                        }
                        else{
                            errorMessage = true;
                            
                        }
                           
                          
                           

                   }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => {
                            return (
                                <RequestQuoteFormComponent 
                                    background={true} 
                                    btnText="Request a Quote" 
                                    btnTemplate={2}
                                    values={values}
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    errorMessage={errorMessage ? 'Email or phone number is required' : null}
                                    isLoading={isLoading}
                                />
                            )
                        }
                    }
                </Formik>
                </div>
                <div className={`mt-6 block md-1:hidden z-10 -mx-5`} style={{ backgroundImage: `url(${MobileBackground})` }}>
                    <div className="px-5 pt-38px pb-38px">
                        <Formik initialValues={initialValues} onSubmit={async(values)=>{
                         if(!!values.email.length || !!values.phone.length){
                            setLoading(true)
                            const response = await requestQuote({name : values.name , phone_number : values.phone , email : values.email})
                            try {
                                if( response.status === "SUCCESS"){
                                    setShowModal(true)
                                    setLoading(false)
                                }
                            }
                            catch{
                                console.log(response)
                                setLoading(false)
                            }
                        }
                        else{
                            errorMessage = true;
                            
                        }
                           
                          
                           

                   }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => {
                                return (
                                    <RequestQuoteFormComponent 
                                        position="vertical" background={true} btnText="Request a Quote"
                                        btnTemplate={2}
                                        values={values}
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}
                                        errorMessage={errorMessage ? 'Email or phone number is required' : null}
                                    isLoading={isLoading}
                                    />
                                )
                            }
                        }
                        </Formik>
                    </div>
                </div>
                
            </div>
            {showModal && <SuccessModal setShowModal={setShowModal}/>} 

            <div className="hidden md-1:block">
                <img className="h-full xl:w-full object-cover rounded-tl-2xl" src={ HeroImg }/>
            </div>
        </div>
    );
};
export default Intro;