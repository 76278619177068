import React, { useEffect, useState } from 'react';
import data from '@data/template-2/homepage.json';
import { graphql } from 'gatsby';
import Intro from '@containers/template-2/homepage/intro';
// import TradeCategories from '@containers/template-2/homepage/trade-categories';
import OurProcess from '@containers/template-2/homepage/our-process';
import CustomerReviews from '../../containers/template-2/homepage/customer-reviews';
// import Guarantees from '@containers/template-2/homepage/guarantees';
import FAQ from '@containers/template-2/homepage/faq';
import AboutUs from '@containers/template-2/homepage/about-us';
import ContactUs from '@containers/template-2/homepage/contact-us';
import CallToAction from '@containers/template-2/homepage/call-to-action';
import Layout from '@components/commons/template-2/layout';
import OurServices from '../../containers/template-2/homepage/our-services';
import { getRandomFaqs } from '../../utils/getRandomFaqs';
import { getRandomUsp } from '../../utils/getRandomUsp';
import BusinessFeature from '@containers/template-2/homepage/business-feature';

const IndexPage = ({
    data: {
        allStrapiWebsites: { nodes },
        allStrapiFaqs,
        allStrapiUniqueSellingPropositions
    },
}) => {
    const [usp,setUsp] = useState(null)
    useEffect(()=>{
        setUsp(getRandomUsp(allStrapiUniqueSellingPropositions.nodes))
    },[])
    const businessData = nodes[0].business;

    const businessLayoutData = {
        logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        services: businessData.highlightedServices,
        ...businessData
    };
    const heroData = {
        usp,
        title: businessData.displayName,
        subTitle: businessData.shortDescription,
        image: businessData.coverImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
    };

    const ourProcessData = {
        ...data.ourProcess,
        process: businessData.process ? businessData.process : [],
    };

    const customerReviewData = {
        ...data.customerReview,
        feedbacks: businessData.feedbacks,
    };

    const faqData = {
        ...data.faq,
        faqs: getRandomFaqs(allStrapiFaqs.nodes) ,
    };

    const servicesData = {
        ...data.ourService,
        services: businessData.highlightedServices,
    };

    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription,
        domain: nodes[0].websiteDomain.name,
        logo: businessData.logoImage?.url ?? null,
        previewImage: businessData.coverImage?.url ?? null,
    };
    
    const aboutUsData = {
        ...data.aboutUs,
        text: businessData.longDescription,
        image: businessData?.images?.[0]?.localFile?.childImageSharp?.fluid ?? null,
    }

    const contactUsData = {
        ...data.area,
        ...data.contactUs,
        radius: businessData.radiusServed,
        address: businessData.address,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        socialProfiles: businessData.socialProfiles,
    };
    const businessFeaturesData = {
        ...data.businessFeatures,
        features: businessData.businessFeatures
    };

    return (
        <Layout style={{ background: '#f9f9fc' }} businessData={businessLayoutData} seoData={seoData} faqs={faqData}>
            <Intro data={heroData} />
            {/* <TradeCategories servicesData={servicesData} /> */}
           
            {!!businessData.highlightedServices.length && <OurServices data={servicesData} />}
            {!!businessData.process.length &&  <OurProcess data={ourProcessData} />}
            {!!businessFeaturesData.features.length && <BusinessFeature data={businessFeaturesData}/>}
           
            {!!businessData.feedbacks.length &&  <CustomerReviews data={customerReviewData} />}
           
            {/* <Guarantees /> */}
            {!!faqData.faqs.length &&  <FAQ data={faqData} />  }
                 
            {/* <AboutUs data={aboutUsData} /> */}
            {!!businessData.address &&  <ContactUs data={contactUsData} />}
           
            {/* <CallToAction className="mx-0 md-1:mx-32px lg:mx-16px xl:mx-72px" /> */}
        </Layout>
    );
};

export const query = graphql`
    query ($strapiId: Int!) {
        allStrapiWebsites(filter: { strapiId: { eq: $strapiId } }) {
            nodes {
                websiteDomain {
                    name
                }
                trade_pro {
                    email
                }
                business {
                    logoImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 200) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    phone
                    socialProfiles {
                        id
                        name
                        url
                    }
                    displayName
                    shortDescription
                    longDescription
                    businessFeatures {
                        name
                        description
                    }
                    coverImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 1100) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    highlightedServices {
                        text
                        title
                        photo {
                            url
                            localFile {
                                childCloudinaryAsset {
                                    fluid(maxWidth: 1400) {
                                        ...CloudinaryAssetFluid
                                    }
                                }
                            }
                        }
                    }
                    images {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    process {
                        longDescription
                        shortDescription
                        title
                    }

                    feedbacks {
                        review
                        fullName
                        img {
                            url
                        }
                    }
                    radiusServed
                    address {
                        singleLineAddress
                        longitude
                        latitude
                    }
                }
            }
        }
        allStrapiFaqs{
            nodes {
              websites {
                id
              }
              question
              answer
            }
          }
          allStrapiUniqueSellingPropositions(filter: {websites: {elemMatch: {id: {eq: $strapiId}}}}) {
            nodes {
              usp_options {
                option
                id
              }
            }
          }
    }
`;

export default IndexPage;
