import React from 'react';
import { ServiceHeading } from '@tradesorg/component-library-waas-templates.template-2-service-heading';
import { Template2SliderReview as SliderReview } from '@tradesorg/component-library-waas-templates.template-2-slider-review';
import './index.scss'

const CustomerReviews = ({ data }) => {
    const { slogan, heading, feedbacks } = data;

    const reviewMapper = (feedbacks) => {
        return feedbacks.map(item => ({
                content: item.review,
                reviewBy: {
                    name: item.fullName,
                    title: item.title,
                    avatarUrl: item.img[0].url,
                }
            })
        )
    }

    const reviews = reviewMapper(feedbacks)

    return (
        <div id="testimonials" className="CustomerReviewsComponentWrapper  pt-50px md-1:px-10 xl:pl-98px xl:pr-98px">
            <div className="px-5 md-1:px-0">
                <ServiceHeading
                    sloganText={slogan}
                    heading={heading}
                    half={true}
                    className={{
                        heading: 'mt-5px mb-30px md-1:mb-10 xl:mb-42px',
                    }}
                />
            </div>
            <SliderReview
                items={reviews}
                className="slider slider-wrapper xs:pb-80px md-1:pb-0"
            />
        </div>
    );
};

export default CustomerReviews;