import React from 'react';
import { ServiceHeading } from '@tradesorg/component-library-waas-templates.template-2-service-heading';
import { ServiceTile } from '@tradesorg/component-library-waas-templates.template-2-service-tile';
import ServiceRight from '@assets/images/template-2/service-right.jpg';
import ServiceLeft from '@assets/images/template-2/service-left.jpg';

const OurServices = ({ data }) => {
    const  { slogan, title, text, services } = data

    const defultImage = (inverted) => {
        if(inverted)
            return ServiceRight
        else
            return ServiceLeft
    }

    return (
        <div
            id="services"
            className={`tradeCategoriesComponentWrapper block px-5 md-1:px-10 xl:pl-98px xl:pr-0 pt-16 xl:pb-11`}
        >
            <ServiceHeading
                sloganText={ slogan }
                heading={ title }
                half={true}
                text={ text }
                className={{
                    heading: 'mt-5px',
                    textWrapper: 'mt-3 xs:mb-30px md-1:mb-38px xl:mb-113px max-w-2xl',
                }}
            />
            {services?.map((service, index) => (
                    <span id={`service-${index+1}`}>
                    <ServiceTile
                        title={service.title}
                        buttonProps={{
                            rounded: true,
                            variant: 'secondary',
                            template: 2,
                        }}
                        inverted={index % 2 !== 0}
                        image={!!service.photo[0] ? service.photo[0].url : defultImage(index % 2 !== 0)}
                        // image={service.photo?.[0]?.url ?? null}
                        className={{
                            wrapper: `xs:mb-10 md-1:mb-50px xl:mb-8`,
                            title: `mt-10 text-2xl font-semibold`,
                            content: `mt-20px text-sm md-1:my-6`,
                            buttonWrapper: `mt-3`,
                            img: `w-screen md-1:w-full lg:w-1/2 -mx-5 md-1:mx-0`,
                            rightWrapper: `flex lg:max-w-7xl w-full mx-auto flex-wrap content`,
                            contentWrapper: `w-full lg:w-1/2 lg:p-12 flex flex-col  justify-center xl:max-w-lg xl:mx-auto`,
                        }}
                        content={service.text}
                    />
                    </span>
                ))}
        </div>
    );
};

export default OurServices;
